.muteUnmuteButton {
  --button-size: 2.1rem;
  --button-scale: 1;
  transition: opacity var(--duration-500ms) var(--ease-micro-bounce) var(--duration-200ms);

  --icon-color: var(--color-grey-88);

  &:hover {
    --button-scale: 1.05;
    --icon-color: var(--color-grey-100);
  }
}

.buttonInner {
  position: relative;
  display: block;
  overflow: hidden;
  width: var(--button-size);
  height: var(--button-size);
  transform: scale(var(--button-scale));
  transition: transform var(--duration-300ms) var(--ease-micro-bounce);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2.1rem;
  opacity: 0;
  transform: translate(-50%, -50%);

  * {
    fill: var(--icon-color);
    transition: fill var(--duration-300ms) var(--ease-default);
  }

  &.isActive {
    opacity: 1;
  }
}
