.carousel {
  position: relative;

  --shadow-radius: var(--border-radius-20);

  @media (--lg) {
    overflow: unset;
  }
}

.dummyGrid {
  composes: grid from '~g';

  .dummyGridInner {
    grid-column: start/end;
  }
}

.slidesContainer {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

.paginationDots {
  .oneCard & {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  .twoUp & {
    margin-top: var(--spacer-24);
    grid-column: start/end;
  }

  @media (--md) {
    display: none;
  }
}

.twoUp {
  composes: grid from '~g';

  .dummyGrid,
  .slidesContainer {
    grid-column: start/end;
  }
}
