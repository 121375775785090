.glowWrapper {
  position: relative;
  z-index: var(--z-index-1);

  --wrapper-scale: var(--mobile-wrapper-scale, 0.8);
  @media (--md) {
    --wrapper-scale: 1;
  }
}

.glowBackground {
  --default-glow-skew: 0;
  position: absolute;
  z-index: var(--z-index-neg);
  top: 50%;
  left: 50%;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;

  /* opacity: calc(var(--glow-opacity, 0) * var(--glow-intensity, 1)); */
  pointer-events: none;
  transform: translate3d(calc(var(--glow-translate-x) + var(--glow-offset-x)), calc(var(--glow-translate-y) + var(--glow-offset-y)), 0) scale(calc(var(--glow-multiplier, 1) * var(--glow-scale, 1)));
  transform-origin: center center;

  --glow-opacity: 0;
  --glow-multiplier: 1;
  --glow-translate-x: -50%;
  --glow-translate-y: -50%;
  --glow-offset-x: 0%;
  --glow-offset-y: 0%;

  &.alignCenter {
    --glow-translate-x: -50%;
    --glow-translate-y: -50%;
  }

  &.alignTop {
    --glow-translate-y: calc(- 50% - (var(--glow-scale) - 1) / 2 * 100%);
  }

  &.alignLeft {
    --glow-translate-x: calc(- 50% - (var(--glow-scale) - 1) / 2 * 100%);
  }

  &.alignRight {
    --glow-translate-x: calc(- 50% + (var(--glow-scale) - 1) / 2 * 100%);
  }

  &.alignBottom {
    --glow-translate-y: calc(- 50% + (var(--glow-scale) - 1) / 2 * 100%);
  }
}

.displayed {
  --default-glow-transition-in-delay: 0s;

  /*  scale the canvas */
  --glow-multiplier: calc(var(--canvas-scale) / var(--wrapper-scale, 1));
  --glow-opacity: 1;
  transition: opacity 1.5s var(--glow-transition-in-delay, var(--default-glow-transition-in-delay));
}

.glowCanvas {
  width: calc(var(--wrapper-scale, 1) * 100%);
  height: calc(var(--wrapper-scale, 1) * 100%);
  backface-visibility: hidden;
  opacity: var(--glow-opacity, 1);
  transform: translate3d(0, 0, 0);
  transition: opacity var(--glow-transition-duration, 0.5s);
}
