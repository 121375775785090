.textOverImage {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  background-color: var(--color-grey-00);

  --rounded-top-padding: 0;
  --image-height: 40rem;

  @media (--md) {
    --image-height: 39rem;
  }

  @media (--lg) {
    --image-height: 58.4rem;
  }

  .backgroundWrapper {
    position: absolute;
    z-index: var(--z-index-0);
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (--md) {
      min-height: var(--image-height);
      max-height: 100%;
    }
  }
}

.container {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-1);
  grid-column: start / end;

  &::before {
    width: 100%;
    height: var(--image-height);
    content: '';
    grid-column: col 1 / span var(--grid-number-of-columns);
  }
}

.content {
  z-index: var(--z-index-1);
}
