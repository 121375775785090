.buttonCarouselArrow {
  .buttonIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0%, 0%);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
}
