.buttonToggle {
  position: relative;
}

.isDisabled {
  cursor: not-allowed;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;

  .isIconBefore & {
    margin-right: var(--spacer-8);
  }

  .isIconAfter & {
    margin-left: var(--spacer-8);
  }

  .isActive & {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    * {
      fill: var(--color-grey-100);
    }
  }
}

.labelClasses {
  composes: button from '~typo';
  display: inline-block;
  white-space: nowrap;
}

.labelActive {
  display: block;

  .isActive & {
    display: none;
  }
}

.labelIdle {
  display: none;

  .isActive & {
    display: block;
  }
}
