.paginationDots {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  grid-column: start/end;
}

.paginationDotsContainer {
  position: relative;
  height: 1.3rem;
}

.paginationDotsList {
  display: flex;
  gap: var(--spacer-16);

  .isSlider & {
    position: absolute;
    gap: var(--spacer-8);
  }
}

.dotItem {
  position: relative;
  opacity: 0.3;
  transition: opacity var(--duration-600ms) var(--ease-out);

  &.active {
    opacity: 1;
  }
}

.dotButton {
  position: relative;
  overflow: hidden;
  width: 0.7rem;
  height: 1rem;
  border-radius: 1rem;
}

.dotFigure {
  position: absolute;
  top: calc(50% - 0.35rem);
  left: calc(50% - 0.35rem);
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: var(--color-grey-100);
  transition: transform var(--duration-200ms) var(--ease-out);
}
