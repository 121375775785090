.videoPlaylist {
  position: relative;
  width: 100%;

  --shadow-radius: var(--border-radius-10);
}

.container {
  composes: grid from '~g';
}

.containerVideoPlaylist {
  --featured-column-start: wrapper-start;
  --featured-column-width: wrapper-end;
  --playlist-start: start;
  --playlist-width: end;

  @media (--md) {
    --featured-column-start: col 1;
    --featured-column-width: 6;
    --playlist-start: col 8;
    --playlist-width: 5;
  }
}

.columnFeatured {
  composes: subgrid from '~g';
  position: relative;
  grid-column: var(--featured-column-start) / span var(--featured-column-width);
}

.featuredButtonWrapper {
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-column: 5 / span 1;

  --button-size: 4.8rem;
}

.featuredVideoInfoWrapper {
  composes: grid from '~g';
  margin-bottom: var(--spacer-24);

  @media (--md) {
    display: flex;
    justify-content: space-between;
    padding: 0 2.4rem 2.4rem;
    margin-bottom: 0;
  }

  @media (--lg) {
    padding: 0 4.8rem 4.8rem;
  }
}

.featuredLabel {
  z-index: 2;
  top: auto;
  grid-column: start / span 3;
  text-align: left;

  @media (--md) {
    flex-basis: 80%;
  }

  @media (--lg) {
    flex-basis: 66%;
  }
}

.featuredMediaWrapper {
  composes: cardHover from '~g';
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: var(--shadow-radius);
  aspect-ratio: 1;
  transform: translateZ(0);
}

.featuredPoster {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(calc(var(--card-hover-progress, 0) * var(--default-card-hover-scale-amount) + 1)) translateZ(0);
  transition: var(--card-hover-transform-default-transition);

  --image-aspect-ratio: 1;
}

.featuredTitle {
  composes: title5 from '~typo';
  position: relative;
  margin-bottom: var(--spacer-8);
  color: var(--color-grey-100);
}

.featuredSubtitle {
  composes: label from '~typo';
  position: relative;
  z-index: 2;
  color: var(--color-grey-60);
}

.columnVideoList {
  grid-column: var(--playlist-start) / span var(--playlist-width);

  @media (--md) {
    display: flex;
    align-items: center;
  }
}

.videoListWrapper {
  composes: subgrid from '~g';
  overflow: hidden;
  height: var(--column-height);
  padding: var(--spacer-40) 0 var(--spacer-32);
  margin: 0;
  mask-image: var(--mask-image);
  scrollbar-color: var(--color-alpha-light-20) var(--transparent);
  scrollbar-width: 6px;

  --thumbnail-start: start;
  --thumbnail-width: 1;
  --description-start: col 2;
  --description-width: 3;
  --column-width: var(--grid-computed-column-width);
  --column-gutter: var(--grid-gutter-size);

  /* calculate height based on columns and aspect ratio of image in left column */
  --column-height: calc((var(--column-width) * 3 + var(--spacer-24)) + var(--spacer-32) + var(--spacer-40));
  --mask-image: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 100%) 20%, rgb(0 0 0 / 100%) 78%, rgb(0 0 0 / 20%) 90%, rgb(0 0 0 / 0%) 100%);

  @media (--md) {
    --subgrid-start: col 9;
    --subgrid-width: 6;
    --thumbnail-start: start;
    --thumbnail-width: 2;
    --description-start: col 3;
    --description-width: 4;
    --column-height: calc((var(--column-width) * 4 + var(--column-gutter) * 6));
    overflow: hidden scroll;

    /* calculate height based on columns and aspect ratio of image in left column */
    padding-top: var(--spacer-48);
    padding-right: 1em;
    padding-bottom: var(--spacer-64);
  }

  /* scrollbar */
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-alpha-light-20);
  }

  .isPlaylistExpanded & {
    height: auto;
    mask-image: none;

    @media (--md) {
      height: var(--column-height);
      mask-image: var(--mask-image);
    }
  }
}

.videoListItem {
  composes: subgrid from '~g';
  composes: cardHover from '~g';
  align-items: center;
  justify-content: start;
  margin-bottom: var(--spacer-24);
  text-align: left;

  --subgrid-width: 4;
  --subgrid-start: col 1;

  @media (--md) {
    --subgrid-width: 6;
    --subgrid-start: col 1;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.itemThumbnailButton,
.itemDescriptionButton {
  padding: 0;
  text-align: left;
}

.itemDescriptionButton {
  transform: translate3d(calc(var(--card-hover-progress, 0) * 0.4rem), 0, 0);
  transition: var(--card-hover-transform-default-transition);
}

.itemThumbnailButton {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: var(--border-radius-5);
  grid-column: var(--thumbnail-start) / span var(--thumbnail-width);
  transform: translateZ(0);
}

.itemThumbnail {
  position: relative;
  width: 100%;
  transform: scale(calc(var(--card-hover-progress, 0) * 0.03 + 1)) translateZ(0);
  transition: var(--card-hover-transform-default-transition);

  --image-aspect-ratio: 1;

  @media (--md) {
    --image-aspect-ratio: 16 / 9;
  }
}

.itemDescription {
  grid-column: var(--description-start) / span var(--description-width);
}

.itemTitle,
.itemSubtitleWrapper {
  composes: label from '~typo';
}

.itemTitle {
  color: var(--color-grey-90);
}

.itemSubtitleWrapper {
  color: var(--color-grey-60);
  white-space: nowrap;
}

.itemSubtitle {
  display: inline-block;
}

.itemPlayButton {
  position: absolute;
  right: var(--spacer-8);
  bottom: var(--spacer-8);
}

.viewAllButtonWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacer-24);

  @media (--md) {
    display: none;
  }
}

.dropShadow {
  grid-column: start/end;
}

.buttonPlay,
.videoListItem {
  &:hover,
  [data-button-play-trigger]:hover & {
    --item-hover-progress: 1;

    path {
      fill: var(--button-hover-color);
    }
  }
}
