.modal {
  composes: grid from '~g';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  row-gap: var(--spacer-48);
  visibility: hidden;

  &.shown {
    pointer-events: all;
    visibility: visible;
  }
}

.modalBackground {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  &.blur {
    &::before {
      position: absolute;
      backdrop-filter: blur(50px);
      background-color: var(--color-alpha-black-10);
      content: '';
      inset: 0;
      opacity: 1;
    }
  }

  &.alpha {
    background: var(--color-alpha-dark-90);
    opacity: 1;
  }
}

.modalWrapper {
  position: initial;
  width: calc(100% + var(--modal-touch-area-padding) * 2);
  align-self: center;
  padding: var(--modal-touch-area-padding);
  margin-left: calc(-1 * var(--modal-touch-area-padding));
  grid-column: start / end;

  &.hasFooter {
    @media (--md) {
      align-self: end;
    }
  }

  .closeButton {
    position: absolute;
    z-index: var(--z-index-2);
    right: var(--modal-touch-area-padding, 0);
    bottom: calc(100% + var(--grid-margin-size) - var(--modal-touch-area-padding, 0rem));
    box-shadow: var(--button-box-shadow);

    @media (--md) {
      top: var(--spacer-32);
      right: var(--spacer-32);
      bottom: auto;
    }
  }
}

.modalContent {
  overflow: hidden;
  max-height: calc(100vh - 6rem);
  border-radius: 2em;

  /* lil hack on safari to display border radius
  https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
  isolation: isolate;
}

.modalFooter {
  display: none;
  @media (--md) {
    position: relative;

    /* prevent it going under shadow of wrapper */
    z-index: 2;
    display: block;
    grid-column: start / end;
  }
}
