.tileTable {
  --content-width: 4;
  --group-width: 4;
  composes: grid from '~g';
  position: relative;
  @media (--md) {
    --content-width: 12;
    --group-width: 8;
  }

  @media (--lg) {
    --group-width: 9;
  }
}

.header {
  position: relative;
  padding-bottom: var(--spacer-32);
  margin-bottom: var(--spacer-32);
  grid-column: start / end;

  @media (--md) {
    padding-bottom: var(--spacer-40);
    margin-bottom: var(--spacer-40);
  }

  @media (--lg) {
    padding-bottom: var(--spacer-48);
    margin-bottom: var(--spacer-48);
  }

  &.noKeyline {
    padding-bottom: 0;
    margin-bottom: var(--spacer-24);

    @media (--md) {
      padding-bottom: 0;
      margin-bottom: var(--spacer-16);
    }

    @media (--lg) {
      padding-bottom: 0;
      margin-bottom: var(--spacer-24);
    }
  }
}

.keyline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-alpha-light-20);
  transform-origin: center left;
  transition: transform var(--duration-400ms) var(--ease-out);
}

.headerTitle {
  composes: title3 from '~typo';
  color: var(--color-grey-100);
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 6;
  }

  @media (--lg) {
    grid-column: start / span 8;
  }
}

.tileTable .anchorNav {
  display: none;

  @media (--md) {
    display: block;
    grid-column: start / span 4;
  }

  @media (--lg) {
    grid-column: start / span 3;
  }
}

.anchorNavItems {
  position: sticky;

  /* sticky elements need to account for how much its containing section i  s parallaxing */
  top: calc(var(--scroll-padding-top, 0));

  & > * {
    margin-bottom: var(--spacer-20);

    &:last-child {
      margin-bottom: initial;
    }
  }
}

/* TODO: Rework the animation if needed after we define our animation conventions */
.anchorNavItem {
  composes: body from '~typo';
  position: relative;
  display: block;
  padding-left: 1.25em;
  color: var(--color-grey-60);
  text-align: left;
  text-decoration: none;
  transition: color var(--duration-200ms);

  --dot-scale: 0.5;

  &::before {
    position: absolute;
    top: 0.55rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: var(--color-cobalt);
    content: '';
    opacity: 0;
    transform: scale(var(--dot-scale));
    transition:
      opacity var(--duration-200ms),
      transform var(--duration-200ms);
  }
}

.active {
  color: var(--color-grey-90);

  --dot-scale: 1;

  &::before {
    opacity: 1;
  }
}

.groups {
  composes: subgrid from '~g';

  --subgrid-width: var(--group-width);

  @media (--md) {
    --subgrid-start: col 5;
  }

  @media (--lg) {
    --subgrid-start: col 4;
  }

  & > * {
    margin-bottom: var(--spacer-40);

    @media (--md) {
      margin-bottom: var(--spacer-64);
    }

    &:last-child {
      margin-bottom: initial;
    }
  }

  &.isSingleGroup {
    --subgrid-start: start;
    --subgrid-width: var(--content-width);

    .groupTitle {
      grid-column: start / span 6;
    }
  }
}

.groupContainer {
  --subgrid-start: start;
  --subgrid-width: var(--group-width);
  composes: subgrid from '~g';

  .isSingleGroup & {
    --subgrid-width: var(--content-width);
  }
}

.groupTitle {
  composes: title6 from '~typo';
  margin-bottom: var(--spacer-24);
  grid-column: start / span 4;

  @media (--md) {
    margin-bottom: var(--spacer-20);
    grid-column: start / span var(--group-width);
  }

  @media (--lg) {
    margin-bottom: var(--spacer-24);
  }
}

.groupItems {
  composes: subgrid from '~g';
  gap: var(--spacer-12);

  --subgrid-start: start;
  --subgrid-width: var(--group-width);

  @media (--md) {
    gap: var(--spacer-16);
  }

  @media (--lg) {
    gap: var(--spacer-24);
  }

  .isSingleGroup & {
    --subgrid-width: var(--content-width);
  }
}

.groupItem {
  grid-column: auto / span 2;
  grid-column-gap: var(--grid-gutter-size);

  @media (--md) {
    grid-column: auto / span 4;
  }

  @media (--lg) {
    grid-column: auto / span 3;
  }
}

.tileItem {
  &:hover {
    background-color: transparent;

    --logo-text-item-hover: 1;
  }
}

.label {
  text-align: left;
}
