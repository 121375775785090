.logoTextItem {
  --logo-text-item-hover: 0;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: var(--spacer-20) var(--spacer-24) var(--spacer-18);
  border: 1px solid var(--color-alpha-light-5);
  border-radius: var(--border-radius-5);
  background-color: var(--color-grey-08);
  transition:
    var(--duration-500ms) background-color var(--ease-micro-bounce),
    var(--duration-500ms) border-color var(--ease-micro-bounce);

  &:hover {
    border-color: var(--color-grey-15);
  }

  &.iconOnly {
    align-items: center;
    justify-content: center;

    .iconWrapper {
      margin: 0;
    }
  }

  @media (--md) {
    flex-direction: row;
    align-items: center;
    padding: 2.6rem var(--spacer-24) var(--spacer-28);
  }

  @media (--lg) {
    padding-right: var(--spacer-28);
    padding-left: var(--spacer-28);
  }
}

.iconWrapper,
.labelWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.iconWrapper {
  height: 2.4rem;
  margin-bottom: var(--spacer-8);

  @media (--md) {
    margin-right: var(--spacer-18);
    margin-bottom: initial;
  }
}

.icon {
  display: flex;
  height: 100%;
}

.labelWrapper {
  overflow: hidden;
  text-overflow: ellipsis;

  @media (--md) {
    height: 2.6rem;
  }
}

.label {
  composes: formDisclaimer from '~typo';
  composes: ellipsis from '~deco';
  color: var(--color-grey-70);
}

.chiclet {
  flex-direction: row;
  border: 1px solid var(--color-alpha-light-10);
  background-color: var(--color-alpha-dark-70);

  .icon {
    margin-right: var(--spacer-24);
    margin-bottom: initial;
  }

  .label {
    color: var(--color-grey-80);
  }
}
