.dim {
  background: var(--overlay-dim);
}

.light {
  background: var(--overlay-light);
}

.medium {
  background: var(--overlay-medium);
}

.heavy {
  background: var(--overlay-heavy);
}

.dim20 {
  background: rgb(0 0 0 / 20%);
}

.dim40 {
  background: var(--overlay-dim40);
}

.dim60 {
  background: var(--overlay-dim60);
}

.dim80 {
  background: var(--overlay-dim80);
}

.dim100 {
  background: rgb(0 0 0 / 100%);
}
