.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: var(--aspect-ratio, var(--default-aspect-ratio, 16/9));
  background-color: var(--color-grey-00);
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;

  &.shown {
    visibility: visible;
  }
}

.video {
  position: relative;
  transition: opacity 0.2s ease-in-out;

  @media (--sm-only) {
    /* force the video to be visible to ensure the play with sound won't fail */
    /* stylelint-disable-next-line declaration-no-important */
    opacity: 1 !important;
  }
}

.cover {
  video {
    object-fit: cover;
  }
}

@media all and (display-mode: fullscreen) {
  .hasControls {
    .cover {
      video {
        object-fit: contain;
      }
    }
  }
}
