.controlPanel {
  position: absolute;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  --visible-progress: 0;
}

.centerPlayPauseButton {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: var(--visible-progress);
  transform: translate(-50%, -50%);
  transition: opacity var(--duration-300ms) var(--ease-basic-butter);

  &.isVisible {
    --visible-progress: 1;
  }
}

.bottomPanel {
  display: none;

  @media (--md) {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 var(--spacer-40);
    padding-bottom: var(--spacer-24);
    opacity: var(--visible-progress);
    transform: translateY(calc((1 - var(--visible-progress)) * 100%));
    transition:
      opacity var(--duration-300ms) var(--ease-basic-butter),
      transform var(--duration-300ms) var(--ease-default);

    &.isVisible {
      --visible-progress: 1;
    }
  }

  /* shadow for readability */
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(180deg, var(--transparent) 10%, var(--color-alpha-black-90) 130%);
    content: '';
    pointer-events: none;
  }
}

.bottomPanelDetails {
  margin-bottom: var(--spacer-32);
}

.detailsTitle {
  composes: title5 from '~typo';
  margin-bottom: var(--spacer-8);
}

.detaislSubtitle {
  composes: label from '~typo';
  color: var(--color-grey-70);
}

.bottomPanelControls {
  display: grid;
  width: 100%;
  align-items: center;
  gap: var(--spacer-24);
  grid-template-columns: 1.6rem 1.6rem auto 2.4rem;
}

.bottomPanelPlayPauseButton {
  grid-column: 1;
}

.bottomPanelMuteUnmuteButton {
  grid-column: 2;
}

.track {
  grid-column: 2 / span 2;

  &.hasMuteButton {
    grid-column: 3;
  }
}

.bottomPanelExpandButton {
  width: var(--spacer-24);
  height: var(--spacer-24);
  grid-column: 4;
  transform: scale(var(--button-scale));
  transition: transform var(--duration-300ms) var(--ease-micro-bounce);

  --button-scale: 1;
  --icon-color: var(--color-grey-88);

  &:hover {
    --button-scale: 1.05;
    --icon-color: var(--color-grey-100);
  }

  path {
    fill: var(--icon-color);
    transition: fill var(--duration-300ms) var(--ease-default);
  }
}
