/* We ignore prettier for this class definition to prevent line collapsing on the template */

/* prettier-ignore */
.grid {
  /*
  List of required properties to be defined in :root
  --grid-max-content-width (requires a unit)
  --grid-margin-size (requires a unit)
  --grid-gutter-size (requires a unit)
  --grid-number-of-columns
  */
  display: grid;
  width: 100%;
  grid-column-gap: var(--grid-gutter-size, 20px);
  grid-template-rows: auto;
  grid-template-columns: [wrapper-start] minmax(calc(var(--grid-margin-size, 40px) - var(--grid-gutter-size, 20px)), 1fr) [start] repeat(var(--grid-number-of-columns, 12), [col] minmax(0, calc(var(--grid-max-content-width) / var(--grid-number-of-columns, 12) - var(--grid-gutter-size, 20px) * (var(--grid-number-of-columns, 12) - 1) / var(--grid-number-of-columns, 12))))[end] minmax( calc(var(--grid-margin-size, 40px) - var(--grid-gutter-size, 20px)), 1fr)[wrapper-end]; /* stylelint-disable-line */
}

/* prettier-ignore */
.subgrid {
  /*
  List of required properties to be defined in element
  --subgrid-start
  --subgrid-width
  */
  display: grid;
  grid-column: var(--subgrid-start, col 1) / span var(--subgrid-width, var(--grid-number-of-columns));
  grid-column-gap: var(--grid-gutter-size, 20px);
  grid-template-columns: [start] repeat(var(--subgrid-width, var(--grid-number-of-columns)), [col] minmax(0, 1fr)) [end]; /* stylelint-disable-line */
  grid-template-rows: auto;
}

/* We ignore prettier for this class definition to prevent line collapsing on the template */

/* prettier-ignore */
.standaloneGrid {
  display: grid;
  width: 100%;
  max-width: var(--grid-max-content-width);
  margin: auto;
  grid-column-gap: var(--grid-gutter-size, 20px);
  grid-template-columns: [start] repeat(var(--grid-number-of-columns), [col] minmax(0, 1fr)) [end];
  grid-template-rows: auto;
}

.cardHover {
  --card-hover-progress: 0;
  --card-hover-duration: var(--duration-500ms);
  --card-hover-ease: var(--ease-basic-butter);
  --card-hover-transform-default-transition: var(--card-hover-duration) transform var(--card-hover-ease);
  @media (hover: hover) {
    &:hover {
      --card-hover-progress: 1;
    }
  }
}

.cardAndButtonHover {
  composes: cardHover;

  --item-hover-progress: 0;
  --button-hover-progress: 0;
  @media (hover: hover) {
    &:hover {
      --item-hover-progress: 1;
      --button-hover-progress: 1;

      path {
        fill: var(--button-hover-color, var(--color-grey-97));
      }
    }
  }
}

.carouselArrowsContainer {
  @media (--md) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 192rem;
    transform: translateX(-50%);
  }

  .carouselArrowsButtonPrev,
  .carouselArrowsButtonNext {
    --button-size: 4.4rem;
    --button-width-half: calc(var(--button-size) / 2);
    z-index: var(--z-index-2);
    display: none;

    @media (--md) {
      position: absolute;
      top: calc(var(--button-width-half) * -1);
      display: block;
    }

    &.carouselArrowsButtonDisabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .carouselArrowsButtonPrev {
    @media (--md) {
      left: var(--spacer-24);
    }
    @media (--lg) {
      left: var(--spacer-32);
    }
  }

  .carouselArrowsButtonNext {
    @media (--md) {
      right: var(--spacer-24);
    }
    @media (--lg) {
      right: var(--spacer-32);
      left: auto;
    }
  }
}
