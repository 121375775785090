.modal {
  width: 100%;
  margin: 0 auto;

  @media (--md) {
    grid-column: 3 / span 10;
  }

  @media (--lg) {
    grid-column: 5 / span 6;
  }
}

.modalContent {
  max-height: calc(100vh - var(--spacer160));
  padding: var(--spacer-96) var(--spacer-32) var(--spacer-24);
  background-color: var(--color-alpha-dark-90);

  &::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-alpha-light-10);
    border-radius: var(--border-radius-20);
    background: var(--overlay-light);
    content: '';
    inset: 0;
  }
  @media (--md) {
    padding: var(--spacer-64) var(--spacer-48) var(--spacer-40);
  }
}

.closeButton {
  --modal-touch-area-padding: var(--spacer-32);
  top: var(--spacer-32);
  right: var(--spacer-32);
  bottom: auto;
  width: var(--spacer-40);
  height: var(--spacer-40);
}

.slidesContainer {
  position: relative;
  display: flex;
}

.slideItem {
  composes: subgrid from '~g';
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  align-content: start;

  @media (--md) {
    display: block;
    margin-bottom: var(--spacer-48);
  }
}

.modalHeader {
  margin-bottom: var(--spacer-16);
  grid-column: start / end;

  @media (--md) {
    grid-column: 2 / span 8;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacer-10);

  @media (--md) {
    margin-bottom: var(--spacer-16);
  }
}

.icon {
  max-height: 2.6rem;
  flex-shrink: 0;
}

.label {
  composes: title3 from '~typo';
}

.description {
  display: flex;
  margin-bottom: var(--spacer-16);
  grid-column: start / end;

  @media (--md) {
    max-width: 79%;
  }
}

.link {
  display: flex;
  height: 2.4rem;
  align-items: center;
  color: var(--color-grey-60);
  grid-column: start / end;
  place-self: start;

  &:hover {
    color: var(--color-grey-90);
  }

  a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .arrowDiagonal {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -1rem;

    path {
      fill: currentcolor;
    }
  }

  .icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin-left: -1rem;

    path {
      fill: currentcolor;
    }
  }
}

.carouselArrowsContainer {
  display: none;
  width: 100%;

  --arrow-keyline-color: var(--color-alpha-perano-10);

  @media (--md) {
    display: block;
  }

  .carouselArrows {
    position: relative;
  }
}

.carouselPagination {
  padding: var(--spacer-48) 0 0;

  @media (--md) {
    display: none;
  }
}
