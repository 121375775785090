.track {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;

  --bar-radius: 1rem;
}

.trackBars {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.durationBar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: var(--spacer-4);
  border-radius: var(--bar-radius);
  background-color: var(--color-alpha-light-20);
}

.trackPointer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.2rem;
  background-color: var(--color-grey-100);
  transform: translateX(calc(var(--hover-progress) * 100%));
}

.trackBufferBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: var(--bar-radius);
  background-color: var(--color-alpha-light-30);
  pointer-events: none;
  transform: translateX(var(--buffer-progress, -100%));
  transition: transform var(--ease-default) var(--duration-200ms);
}

.trackProgressBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: var(--bar-radius);
  background-color: var(--color-grey-88);
  pointer-events: none;
  transform: translateX(var(--playing-progress, -100%));
  transition: transform var(--ease-default) var(--duration-200ms);
}

.remainingTime {
  composes: body from '~typo';
  position: relative;
  display: block;
  min-width: 5rem;
  flex: 5rem;
  margin-left: var(--spacer-24);
  color: var(--color-grey-100);
  text-align: right;
}

.remainingTimeButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
